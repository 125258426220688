import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { AuthContextData, AuthState } from 'contexts';
import { servicoBase } from 'services/servico-base';
import { Login } from 'models/login';
import {
  getHashedUrlTermos,
  verificarSeAceitouTermos,
} from '../../services/termos';

export function useBloc() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [dispositivoId] = useState<string>(() => {
    const localDispositivoId = localStorage.getItem('user:device-id');

    if (localDispositivoId !== null) {
      return localDispositivoId;
    }

    const newdispositivoId = uuid();

    localStorage.setItem('user:device-id', newdispositivoId);

    return newdispositivoId;
  });

  const [data, setData] = useState<AuthState>(() => {
    const id = localStorage.getItem('id') || '';
    const nome = localStorage.getItem('nome') || '';
    const token = localStorage.getItem('token');
    const login = localStorage.getItem('login') || '';
    const setor = localStorage.getItem('setor') || '';
    const podeAssinar = localStorage.getItem('podeAssinar');
    const ehAdmin = localStorage.getItem('ehAdmin');
    const comunicados = localStorage.getItem('comunicados');
    const email = localStorage.getItem('email') || '';
    const permissaoParaBaixarArquivos =
      localStorage.getItem('permissaoParaBaixarArquivos') || '';
    const permissaoParaCompartilharArquivos =
      localStorage.getItem('permissaoParaCompartilharArquivos') || '';

    if (token) {
      servicoBase.defaults.headers.common.Authorization = `Bearer ${token}`;

      return {
        id,
        nome,
        token,
        login,
        setor,
        ehAdmin: ehAdmin === 'true',
        podeAssinar: podeAssinar === 'true',
        comunicados: comunicados !== 'false',
        email,
        permissaoParaBaixarArquivos: permissaoParaBaixarArquivos === 'true',
        permissaoParaCompartilharArquivos:
          permissaoParaCompartilharArquivos === 'true',
      };
    }
    return {} as AuthState;
  });

  const deslogar = useCallback(() => {
    try {
      const storageddispositivoId = localStorage.getItem('user:device-id');
      localStorage.clear();

      if (storageddispositivoId) {
        localStorage.setItem('user:device-id', storageddispositivoId);
      }
      navigate('/');
    } catch (error) {
      console.info({ error });
    }
  }, [navigate]);

  const atualizarParametrosLogin = useCallback((login: Login) => {
    setData(oldState => ({
      ...oldState,
      id: login.usuario?.id || '',
      token: login.token,
      nome: login.usuario?.nome || '',
      login: login.usuario?.login || '',
      setor: login.usuario?.setor || '',
      ehAdmin: login.usuario?.is_admin || false,
      podeAssinar: login.usuario?.pode_assinar,
      comunicados: !!login.usuario?.notificacoes_pendentes.length,
      email: login.usuario?.email,
      permissaoParaBaixarArquivos:
        login.usuario?.permissao_para_baixar_arquivos || false,
      permissaoParaCompartilharArquivos:
        login.usuario?.permissao_para_compartilhar_arquivos || false,
    }));

    localStorage.setItem('id', login.usuario.id);
    localStorage.setItem('token', login.token);
    localStorage.setItem('nome', login.usuario.nome);
    localStorage.setItem('login', login.usuario.login);
    localStorage.setItem('setor', login.usuario.setor);
    localStorage.setItem('email', login.usuario.email);
    localStorage.setItem(
      'ehAdmin',
      (login.usuario?.is_admin || false).toString(),
    );
    localStorage.setItem(
      'podeAssinar',
      login.usuario?.pode_assinar?.toString() || 'false',
    );
    localStorage.setItem(
      'permissaoParaBaixarArquivos',
      login.usuario?.permissao_para_baixar_arquivos?.toString() || 'false',
    );
    localStorage.setItem(
      'permissaoParaCompartilharArquivos',
      login.usuario.permissao_para_compartilhar_arquivos?.toString() || 'false',
    );
    localStorage.setItem(
      'comunicados',
      login.usuario?.notificacoes_pendentes?.toString() || 'false',
    );
  }, []);

  const logar = useCallback(
    async (login: Login) => {
      try {
        atualizarParametrosLogin(login);
        servicoBase.defaults.headers.common.Authorization = `Bearer ${login.token}`;
        window.location.href = '/app/documentos';
      } catch (error) {
        console.info({ error });
      }
    },
    [atualizarParametrosLogin],
  );
  const memoValue: AuthContextData = useMemo(() => {
    return {
      id: data.id,
      token: data.token,
      nome: data.nome,
      login: data.login,
      setor: data.setor,
      podeAssinar: data.podeAssinar ?? false,
      ehAdmin: data.ehAdmin ?? false,
      comunicados: data.comunicados ?? false,
      email: data.email,
      permissaoParaBaixarArquivos: data.permissaoParaBaixarArquivos ?? false,
      permissaoParaCompartilharArquivos:
        data.permissaoParaCompartilharArquivos ?? false,
      logar,
      deslogar,
      loading,
      dispositivoId,
    };
  }, [data, logar, deslogar, loading, dispositivoId]);

  const verificarSeEstaLogado = useCallback(async () => {
    const loadingDiv = document.getElementById('pre-loading') as HTMLDivElement;

    try {
      const { token, login } = data;
      if (!token && location.pathname !== '/') {
        window.location.href = '/';
        return;
      }
      if (!token) {
        return;
      }
      const aceitou = await verificarSeAceitouTermos(login!);
      if (!aceitou) {
        const url = await getHashedUrlTermos(login!);
        window.location.href = url;
        return;
      }
      if (location.pathname === '/') {
        window.location.href = '/app/documentos';
      }
      servicoBase.defaults.headers.common.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.info(error);
    } finally {
      setTimeout(() => {
        loadingDiv.setAttribute('style', 'display: none;');
        setLoading(() => false);
      }, 1000);
    }
  }, [data, location.pathname]);

  useEffect(() => {
    if (!window.name) {
      window.name = uuid().replaceAll(/-/g, '');
    }
  }, []);

  useEffect(() => {
    window.deslogar = deslogar;
  }, [deslogar]);

  useLayoutEffect(() => {
    verificarSeEstaLogado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { memoValue, loading };
}
